import React, { useCallback, useEffect, useRef, useState } from 'react'
import useKeyboardShortcut from 'use-keyboard-shortcut'
import styles from './Validation.module.scss'
import { CardList } from '../../../components/CardList/CardList'
import { EventFiltersForm } from '../../../components/EventFilters/EventFilters'
import {
  CanvasStyle,
  CanvasViewTypes,
  Strack
} from '../../../components/Strack/Strack.types'
import { useAppDispatch } from '../../../store/hooks'
import {
  createGameEvent,
  updateEvent,
  updateFlight
} from '../../../metrics_server/events/actions'
import {
  useEvents,
  useSelectedFormattedEvent
} from '../../../metrics_server/events/hooks'
import { useSelectedFormattedSession } from '../../../metrics_server/sessions/hooks'
import { FormattedEventDetailCard } from '../../../components/EventDetailCard/FormattedEventDetailCard'
import { ValidationTable } from '../../../metrics_server/events/components/ValidationTable'
import { EventsFilters } from '../../../metrics_server/events/filter'
import { isOutcomeType } from '../../../metrics_server/outcomes/data_types'
import { StrackEvents } from '../../../components/Strack/StrackEvents/StrackEvents'
import { SortingState } from '@tanstack/react-table'
import { eventTypes } from '../../../metrics_server/events/data_types'
import { gameEventTypes } from '../../../metrics_server/events/game/data_types'

interface ValidationProps {
  strackReady: boolean
  strack: Strack
  canvasStyle: CanvasStyle
  canvasView: CanvasViewTypes
  active: boolean

  validationEventsFilters: EventsFilters
  validationTableSorting: SortingState
  setValidationTableSorting: (sorting: SortingState) => void
  timeColumn: string
  setTimeColumn: (column: string) => void

  highlightedRow: string
  setHighlightedRow: (flightId: string) => void

  updateCanvasWidth?: (canvasId: string, width: string) => void
  setCanvasStyle?: (canvasId: string, width: string) => void
  canvasId?: string
  hideCanvas?: (hide: boolean) => void
}

export function Validation(props: ValidationProps) {
  const {
    // Strack Container
    strackReady,
    strack,
    canvasStyle,
    canvasView,
    active,
    validationEventsFilters,
    validationTableSorting,
    setValidationTableSorting,
    timeColumn,
    setTimeColumn,
    canvasId,
    updateCanvasWidth,
    setCanvasStyle,
    hideCanvas
  } = props
  // Store //

  const dispatch = useAppDispatch()
  // ===== //

  // Session //
  // TODO: refactor needed - name clash with sessionConfig for strack and this hook
  const formattedSession = useSelectedFormattedSession()
  const {
    teamsSessions,
    playersSessions,
    isTrainingMode,
    live,
    sport,
    flightTypes,
    isKeyboardShortcutEnabled
  } = formattedSession
  // ========//

  // Event //
  const { selectedEventId } = useEvents()
  const formattedEvent = useSelectedFormattedEvent()

  const tableRef = useRef(null)
  const filterRef = useRef(null)
  const eventDetailRef = useRef(null)
  const refLeft = useRef(null)

  // Validation State //
  const [saveFlash, setSaveFlash] = useState(null)
  const [hideDetailCard, setHideDetailCard] = useState(false)

  const validationTableId = 'validationTable'

  // KeyBoard shortcuts

  const [isInputFocused, setIsInputFocusStatus] = useState(false)

  // TODO: this is a problem - we need a way to disable hotkeys when we are typing in an input
  // useEffect(() => {
  //   // Add event listeners to all input elements
  //   const inputs = document.querySelectorAll('input') // Include other input elements if needed

  //   const enableInputFocusStatus = () => setIsInputFocusStatus(true)
  //   const disableInputFocusStatus = () => setIsInputFocusStatus(false)

  //   inputs.forEach((input) => {
  //     console.log(input)
  //     input.addEventListener('focus', enableInputFocusStatus)
  //     input.addEventListener('blur', disableInputFocusStatus)
  //   })

  //   return () => {
  //     // Remove event listeners on cleanup
  //     inputs.forEach((input) => {
  //       input.removeEventListener('focus', enableInputFocusStatus)
  //       input.removeEventListener('blur', disableInputFocusStatus)
  //     })
  //   }
  // }, [])

  const updateFlightOutcomeBasedOnHotKey = useCallback(
    (newValue) => {
      if (active && isKeyboardShortcutEnabled) {
        if (formattedEvent) {
          dispatch(
            updateFlight({
              id: formattedEvent.id,
              outcome: newValue
            })
          )
        }
      }
    },
    [formattedEvent, active, isKeyboardShortcutEnabled]
  )

  const { keyboardShortcuts } = sport.props.features

  Object.keys(keyboardShortcuts?.shortcuts || {}).forEach((key) => {
    const { condition, type, outcome, payload, subType } =
      keyboardShortcuts.shortcuts[key]
    useKeyboardShortcut(
      [key],
      (key) => {
        if (condition) {
          if (condition(formattedEvent, flightTypes)) {
            if (formattedEvent.eventType === eventTypes.items.game.value) {
              if (
                formattedEvent.type.selected?.value ===
                gameEventTypes.items.touchDown?.value
              ) {
                dispatch(
                  updateEvent({
                    id: formattedEvent.id,
                    confirmed: true
                  })
                )
              } else {
                dispatch(
                  updateEvent({
                    id: formattedEvent.id,
                    subType
                  })
                )
              }
            } else {
              updateFlightOutcomeBasedOnHotKey(outcome)
            }
          }
        }
        if (type === 'createGameEvent') {
          dispatch(
            createGameEvent(formattedSession.id, {
              type: payload.value
            })
          )
        }
      },
      {
        overrideSystem: isKeyboardShortcutEnabled && active
      }
    )
  })

  const [hotKeyData, setHotKeyData] = useState({
    key: null,
    number: '',
    released: false
  })

  useEffect(() => {
    if (isKeyboardShortcutEnabled && active) {
      const handleKeyDown = (e) => {
        const key = e.key.toLowerCase()

        if (
          (key === 'q' || key === 't' || key === 'p' || key === 'r') &&
          !e.repeat
        ) {
          setHotKeyData((prevData) => ({
            ...prevData,
            key,
            released: false
          }))
        }

        if (!isNaN(key) && !e.repeat) {
          setHotKeyData((prevData) => ({
            ...prevData,
            number: prevData.number + key
          }))
        }
      }

      const handleKeyRelease = (e) => {
        const key = e.key.toLowerCase()

        if (
          (key === 'q' || key === 't' || key === 'p' || key === 'r') &&
          !e.repeat
        ) {
          setHotKeyData((prevData) => ({
            ...prevData,
            released: true
          }))
        }
      }

      window.addEventListener('keydown', handleKeyDown)
      window.addEventListener('keyup', handleKeyRelease)

      return () => {
        window.removeEventListener('keydown', handleKeyDown)
        window.removeEventListener('keyup', handleKeyRelease)
      }
    }
  }, [isKeyboardShortcutEnabled, active])

  useEffect(() => {
    if (hotKeyData.released) {
      const { key, number } = hotKeyData

      if (formattedEvent?.team.selected) {
        const teamId = formattedEvent.team.selected.id

        // Handle team selection (q1 or q2)
        if (
          key === 'q' &&
          (number === '1' || number === '2') &&
          !isTrainingMode
        ) {
          let newTeamId
          if (number === '1') {
            newTeamId = formattedSession.homeTeam.id // Home team
          } else if (number === '2') {
            newTeamId = formattedSession.awayTeam.id // Away team
          }

          if (newTeamId !== teamId) {
            const data = {
              id: formattedEvent.id,
              teamId: newTeamId,
              fromPlayerId: null
            }

            // Dispatch action to update team
            if (formattedEvent.eventType === eventTypes.items.flight.value) {
              dispatch(updateFlight(data, false))
            } else if (
              formattedEvent.eventType === eventTypes.items.game.value
            ) {
              dispatch(updateEvent(data))
            }

            console.log(
              `Team changed to ${number === '1' ? 'Home' : 'Away'} team:`,
              newTeamId
            )
          }
        }

        // Handle other hotkeys (p, r, t)
        else {
          let playerTeamId = teamId
          // If interception and the hotkey is for receiver use opposition team id
          if (
            key === 'r' &&
            isOutcomeType.interception(formattedEvent.outcome?.selected)
          ) {
            playerTeamId = teamsSessions.map[teamId].oppositionTeamId
          }

          const playerSession =
            playersSessions.byNumber[playerTeamId]?.map[number]

          if (playerSession) {
            if (formattedEvent.eventType === eventTypes.items.flight.value) {
              const data = {
                id: formattedEvent.id,
                fromPlayerId:
                  key === 'p'
                    ? playerSession.playerId
                    : formattedEvent.player?.selected?.id,
                toPlayerId:
                  key === 'r'
                    ? playerSession.playerId
                    : (formattedEvent.metrics?.toPlayerId?.value as string),
                targetPlayerId:
                  key === 't'
                    ? playerSession.playerId
                    : (formattedEvent.metrics?.targetPlayerId?.value as string),
                teamID: teamId
              }
              dispatch(updateFlight(data, false))
            } else if (
              formattedEvent.eventType === eventTypes.items.game.value
            ) {
              const data = {
                id: formattedEvent.id,
                playerId:
                  key === 'p'
                    ? playerSession.playerId
                    : formattedEvent.player?.selected?.id,
                teamId: teamId
              }
              dispatch(updateEvent(data))
            }
          }
        }
      }

      // Reset hotKeyData state
      setHotKeyData({
        key: null,
        number: '',
        released: false
      })
    }
  }, [
    hotKeyData.released,
    formattedEvent,
    playersSessions,
    dispatch,
    updateFlight,
    formattedSession
  ])

  useEffect(() => {
    const resizeableTable = tableRef.current
    const resizeableFilter = filterRef.current
    const resizeableEventDetail = eventDetailRef.current
    const resizerLeft = refLeft.current

    // Early return if any of the required elements are not mounted
    if (
      !resizeableTable ||
      !resizeableFilter ||
      !resizeableEventDetail ||
      !resizerLeft
    ) {
      return
    }

    try {
      const stylesTable = window.getComputedStyle(resizeableTable)
      const stylesFilter = window.getComputedStyle(resizeableFilter)
      const stylesEventDetail = window.getComputedStyle(resizeableEventDetail)

      let widthTable = parseInt(stylesTable.width, 10)
      let widthFilter = parseInt(stylesFilter.width, 10)
      let widthEventDetail = parseInt(stylesEventDetail.width, 10)
      let x = 0

      const onMouseMoveLeftResize = (event) => {
        const dx = event.clientX - x
        x = event.clientX
        widthTable = widthTable - dx
        widthFilter = widthFilter - dx
        resizeableTable.style.width = `${widthTable}px`
        resizeableFilter.style.width = `${widthFilter}px`

        if (dx > 0) {
          const newWidth = widthEventDetail + dx
          widthEventDetail = newWidth
        } else {
          const newWidth = widthEventDetail - Math.abs(dx)
          widthEventDetail = newWidth
          hideCanvas(true)
        }

        resizeableEventDetail.style.width = `${widthEventDetail}px`
      }

      const onMouseUpLeftResize = (event) => {
        const dx = event.clientX - x
        hideCanvas(false)
        if (dx > 0) {
          const newWidth = widthEventDetail + dx + 'px'
          updateCanvasWidth(canvasId, newWidth)
        } else {
          const newWidth = widthEventDetail - Math.abs(dx) + 'px'
          updateCanvasWidth(canvasId, newWidth)
        }
        document.removeEventListener('mousemove', onMouseMoveLeftResize)
        document.removeEventListener('mouseup', onMouseUpLeftResize)
      }

      const onMouseDownLeftResize = (event) => {
        x = event.clientX
        resizeableTable.style.right = styles.right
        resizeableTable.style.left = null
        resizeableFilter.style.right = styles.right
        resizeableFilter.style.left = null
        document.addEventListener('mousemove', onMouseMoveLeftResize)
        document.addEventListener('mouseup', onMouseUpLeftResize)
      }

      resizerLeft.addEventListener('mousedown', onMouseDownLeftResize)

      return () => {
        resizerLeft.removeEventListener('mousedown', onMouseDownLeftResize)
      }
    } catch (error) {
      console.error('Error setting up resize handlers:', error)
    }
  }, [])

  // Don't render anything if the tab is not active
  if (!active) {
    return null
  }

  return (
    <React.Fragment>
      <StrackEvents
        events={formattedEvent ? [formattedEvent.rawData] : []}
        active={active}
        canvasView={canvasView}
        strack={strack}
        canvasStyle={canvasStyle}
        strackReady={strackReady}
      />
      <div className={styles.filterContainer} ref={filterRef}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <EventFiltersForm filters={validationEventsFilters} />
        </CardList>
      </div>
      <div
        className={styles.eventDetailContainer}
        ref={eventDetailRef}
        style={{ display: hideDetailCard && 'none' }}
      >
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
          cardPadding={'4px 8px 4px 8px'}
        >
          <div className={styles.eventDetails}>
            <FormattedEventDetailCard />
          </div>
        </CardList>
      </div>
      {/* <button
        className={styles.hideDetailButton}
        style={{ top: hideDetailCard && '93%' }}
        onClick={() => {
          setHideDetailCard(!hideDetailCard)
          setCanvasStyle(canvasId, !hideDetailCard ? '100%' : '72%')
        }}
      >
        {hideDetailCard ? 'Show' : 'Hide'} Card
      </button> */}
      <div className={styles.tableContainer} ref={tableRef}>
        <div className={styles.tableResize}>
          <div ref={refLeft} style={{ cursor: 'col-resize' }}>
            {/* {strackReady && <SwitchLeftIcon />} */}
          </div>
        </div>
        <div className={styles.tableCard}>
          <CardList
            col={12}
            items={[{}]}
            scrollerId={`scroller-${1}`}
            className='maxHeight'
            cardPadding={'8px 8px 6px 8px'}
          >
            <div className={styles.table}>
              <div className='card-table-container'>
                <ValidationTable
                  eventsFilters={validationEventsFilters}
                  tableId={validationTableId}
                  active={active}
                  validationTableSorting={validationTableSorting}
                  setValidationTableSorting={setValidationTableSorting}
                  isKeyboardShortcutEnabled={isKeyboardShortcutEnabled}
                  timeColumn={timeColumn}
                  setTimeColumn={setTimeColumn}
                />
              </div>
            </div>
          </CardList>
        </div>
      </div>
    </React.Fragment>
  )
}
