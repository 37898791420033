import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import styles from './DrillZone.module.scss'
import { useAppDispatch } from '../../../../store/hooks'
import { updateExistingDrill } from '../../../../metrics_server/drills/thunks'
import { useSelectedFormattedDrill } from '../../../../metrics_server/drills/hooks'
import { useSelectedFormattedSession } from '../../../../metrics_server/sessions/hooks'
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import Checkbox from '../../../Forms/Inputs/Checkbox/Checkbox'
import { DraggableGoal } from './DraggableGoal'
import { Region } from '../../../../metrics_server/drills/types'
import { Sport } from '../../../../metrics_server/sports/data_types'
import _ from 'lodash'

export type GoalStyles = {
  id: number
  x: number
  y: number
  width: number
  height: number
  side: {
    pitch: 'left' | 'right' | 'top' | 'bottom'
    canvas: 'left' | 'right' | 'top' | 'bottom'
  }
}

export type DrillZoneStyles = {
  x: number
  y: number
  width: number
  height: number
  goals: GoalStyles[]
}

type Corners = 'topLeft' | 'topRight' | 'bottomRight' | 'bottomLeft'

const handleGoalPositionOnDrillZoneResize = (
  goals: GoalStyles[],
  newDrillZoneX: number,
  newDrillZoneY: number,
  dx: number,
  dy: number,
  width: number,
  height: number,
  corner: Corners
) => {
  if (!goals) return []

  if (corner === 'topLeft') {
    return goals.map((goal) => {
      if (goal.side.canvas === 'left') {
        let y = goal.y + dy / 2
        if (y + goal.height > newDrillZoneY + height) {
          y = newDrillZoneY + height - goal.height
        } else if (y < newDrillZoneY) {
          y = newDrillZoneY
        }
        return {
          ...goal,
          x: goal.x + dx,
          y
        }
      }
      if (goal.side.canvas === 'top') {
        let x = goal.x + dx / 2
        if (x + goal.width > newDrillZoneX + width) {
          x = newDrillZoneX + width - goal.width
        } else if (x < newDrillZoneX) {
          x = newDrillZoneX
        }
        return {
          ...goal,
          x,
          y: goal.y + dy
        }
      }
      if (goal.side.canvas === 'right') {
        let y = goal.y + dy / 2
        if (y + goal.height > newDrillZoneY + height) {
          y = newDrillZoneY + height - goal.height
        } else if (y < newDrillZoneY) {
          y = newDrillZoneY
        }
        return {
          ...goal,
          x: goal.x,
          y
        }
      }
      if (goal.side.canvas === 'bottom') {
        let x = goal.x + dx / 2
        if (x + goal.width > newDrillZoneX + width) {
          x = newDrillZoneX + width - goal.width
        } else if (x < newDrillZoneX) {
          x = newDrillZoneX
        }
        return {
          ...goal,
          x,
          y: goal.y
        }
      }
    })
  }

  if (corner === 'topRight') {
    return goals.map((goal) => {
      if (goal.side.canvas === 'left') {
        let y = goal.y + dy / 2
        if (y + goal.height > newDrillZoneY + height) {
          y = newDrillZoneY + height - goal.height
        } else if (y < newDrillZoneY) {
          y = newDrillZoneY
        }
        return {
          ...goal,
          x: goal.x,
          y
        }
      }
      if (goal.side.canvas === 'top') {
        let x = goal.x + dx / 2
        if (x + goal.width > newDrillZoneX + width) {
          x = newDrillZoneX + width - goal.width
        } else if (x < newDrillZoneX) {
          x = newDrillZoneX
        }
        return {
          ...goal,
          x,
          y: newDrillZoneY - goal.height
        }
      }
      if (goal.side.canvas === 'right') {
        let y = goal.y + dy / 2
        if (y + goal.height > newDrillZoneY + height) {
          y = newDrillZoneY + height - goal.height
        } else if (y < newDrillZoneY) {
          y = newDrillZoneY
        }
        return {
          ...goal,
          x: goal.x + dx,
          y
        }
      }
      if (goal.side.canvas === 'bottom') {
        let x = goal.x + dx / 2
        if (x + goal.width > newDrillZoneX + width) {
          x = newDrillZoneX + width - goal.width
        } else if (x < newDrillZoneX) {
          x = newDrillZoneX
        }
        return {
          ...goal,
          x,
          y: newDrillZoneY + height
        }
      }
    })
  }

  if (corner === 'bottomRight') {
    return goals.map((goal) => {
      if (goal.side.canvas === 'left') {
        let y = goal.y + dy / 2
        if (y + goal.height > newDrillZoneY + height) {
          y = newDrillZoneY + height - goal.height
        } else if (y < newDrillZoneY) {
          y = newDrillZoneY
        }
        return {
          ...goal,
          x: goal.x,
          y
        }
      }
      if (goal.side.canvas === 'top') {
        let x = goal.x + dx / 2
        if (x + goal.width > newDrillZoneX + width) {
          x = newDrillZoneX + width - goal.width
        } else if (x < newDrillZoneX) {
          x = newDrillZoneX
        }
        return {
          ...goal,
          x,
          y: goal.y
        }
      }
      if (goal.side.canvas === 'right') {
        let y = goal.y + dy / 2
        if (y + goal.height > newDrillZoneY + height) {
          y = newDrillZoneY + height - goal.height
        } else if (y < newDrillZoneY) {
          y = newDrillZoneY
        }
        return {
          ...goal,
          x: goal.x + dx,
          y
        }
      }
      if (goal.side.canvas === 'bottom') {
        let x = goal.x + dx / 2
        if (x + goal.width > newDrillZoneX + width) {
          x = newDrillZoneX + width - goal.width
        } else if (x < newDrillZoneX) {
          x = newDrillZoneX
        }
        return {
          ...goal,
          x,
          y: goal.y + dy
        }
      }
    })
  }

  if (corner === 'bottomLeft') {
    return goals.map((goal) => {
      if (goal.side.canvas === 'left') {
        let y = goal.y + dy / 2
        if (y + goal.height > newDrillZoneY + height) {
          y = newDrillZoneY + height - goal.height
        } else if (y < newDrillZoneY) {
          y = newDrillZoneY
        }
        return {
          ...goal,
          x: goal.x + dx,
          y
        }
      }
      if (goal.side.canvas === 'top') {
        let x = goal.x + dx / 2
        if (x + goal.width > newDrillZoneX + width) {
          x = newDrillZoneX + width - goal.width
        } else if (x < newDrillZoneX) {
          x = newDrillZoneX
        }
        return {
          ...goal,
          x,
          y: goal.y
        }
      }
      if (goal.side.canvas === 'right') {
        let y = goal.y + dy / 2
        if (y + goal.height > newDrillZoneY + height) {
          y = newDrillZoneY + height - goal.height
        } else if (y < newDrillZoneY) {
          y = newDrillZoneY
        }
        return {
          ...goal,
          x: goal.x,
          y
        }
      }
      if (goal.side.canvas === 'bottom') {
        let x = goal.x + dx / 2
        if (x + goal.width > newDrillZoneX + width) {
          x = newDrillZoneX + width - goal.width
        } else if (x < newDrillZoneX) {
          x = newDrillZoneX
        }
        return {
          ...goal,
          x,
          y: goal.y + dy
        }
      }
    })
  }
}

export const getGoalSize = (
  goalStyles: GoalStyles,
  sport: Sport,
  scale: number
) => {
  const goalDimensions = sport.props.pitch.drillsGoalsOrPosts.dimensions
  if (
    goalStyles.height.toFixed(0) ===
      (goalDimensions.small.width * scale).toFixed(0) ||
    goalStyles.width.toFixed(0) ===
      (goalDimensions.small.width * scale).toFixed(0)
  ) {
    return 'small'
  }
  return 'large'
}

export const getGoalOrientation = (goalStyles: GoalStyles) => {
  if (goalStyles.side.pitch === 'left' || goalStyles.side.pitch === 'right') {
    return 'horizontal'
  }
  return 'vertical'
}

export const getCanvasSideFromPitchSide = (
  side: 'left' | 'right' | 'top' | 'bottom',
  strack
) => {
  const pitchCanvasSideMap = {
    left: 'left',
    right: 'right',
    top: 'top',
    bottom: 'bottom'
  }

  if (strack.pitchRotated && strack.pitchFlipped) {
    pitchCanvasSideMap.left = 'bottom'
    pitchCanvasSideMap.right = 'top'
    pitchCanvasSideMap.top = 'left'
    pitchCanvasSideMap.bottom = 'right'
  } else if (strack.pitchRotated) {
    pitchCanvasSideMap.left = 'top'
    pitchCanvasSideMap.right = 'bottom'
    pitchCanvasSideMap.top = 'right'
    pitchCanvasSideMap.bottom = 'left'
  } else if (strack.pitchFlipped) {
    pitchCanvasSideMap.left = 'right'
    pitchCanvasSideMap.right = 'left'
    pitchCanvasSideMap.top = 'bottom'
    pitchCanvasSideMap.bottom = 'top'
  }

  return pitchCanvasSideMap[side] as 'left' | 'right' | 'top' | 'bottom'
}

export const getPitchSideFromCanvasSide = (
  side: 'left' | 'right' | 'top' | 'bottom',
  strack
) => {
  const pitchCanvasSideMap = {
    left: 'left',
    right: 'right',
    top: 'top',
    bottom: 'bottom'
  }

  if (strack.pitchRotated && strack.pitchFlipped) {
    pitchCanvasSideMap.left = 'top'
    pitchCanvasSideMap.right = 'bottom'
    pitchCanvasSideMap.top = 'right'
    pitchCanvasSideMap.bottom = 'left'
  } else if (strack.pitchRotated) {
    pitchCanvasSideMap.left = 'bottom'
    pitchCanvasSideMap.right = 'top'
    pitchCanvasSideMap.top = 'left'
    pitchCanvasSideMap.bottom = 'right'
  } else if (strack.pitchFlipped) {
    pitchCanvasSideMap.left = 'right'
    pitchCanvasSideMap.right = 'left'
    pitchCanvasSideMap.top = 'bottom'
    pitchCanvasSideMap.bottom = 'top'
  }

  return pitchCanvasSideMap[side] as 'left' | 'right' | 'top' | 'bottom'
}

export const getSide = (
  posts: [{ x: number; y: number }, { x: number; y: number }],
  strack
): {
  pitch: 'left' | 'right' | 'top' | 'bottom'
  canvas: 'left' | 'right' | 'top' | 'bottom'
} => {
  const postOne = posts[0]
  const postTwo = posts[1]

  if (Math.abs(postOne.x - postTwo.x) < 0.1) {
    if (postOne.y < postTwo.y) {
      const canvasSide = getCanvasSideFromPitchSide('left', strack)
      return {
        pitch: 'left' as const,
        canvas: canvasSide
      }
    } else {
      const canvasSide = getCanvasSideFromPitchSide('right', strack)
      return {
        pitch: 'right' as const,
        canvas: canvasSide
      }
    }
  } else {
    if (postOne.x < postTwo.x) {
      const canvasSide = getCanvasSideFromPitchSide('top', strack)
      return {
        pitch: 'top' as const,
        canvas: canvasSide
      }
    } else {
      const canvasSide = getCanvasSideFromPitchSide('bottom', strack)
      return {
        pitch: 'bottom' as const,
        canvas: canvasSide
      }
    }
  }
}

export const getGoalStylesFromGoalCoordinates = (
  posts: [
    { x: number; y: number; z: number },
    { x: number; y: number; z: number }
  ],
  strack,
  id
): GoalStyles => {
  if (!posts[0] || !posts[1]) return null

  if (
    (posts[0].x === 0 && posts[0].y === 0 && posts[0].z === 0) ||
    (posts[1].x === 0 && posts[1].y === 0 && posts[1].z === 0)
  )
    return null

  const {
    getCanvasRectStylesFromPitchCoordinates,
    getCanvasCoordinate,
    canvas2DPixelScale
  } = strack

  const postOne = posts[0]
  const postTwo = posts[1]

  const side = getSide(posts, strack)

  let goalCanvasStyles = {
    x: null,
    y: null,
    width: null,
    height: null
  }

  switch (side.pitch) {
    case 'left':
      goalCanvasStyles = getCanvasRectStylesFromPitchCoordinates(
        { x: postTwo.x - 2.4, y: postTwo.y },
        { x: postTwo.x, y: postTwo.y },
        { x: postOne.x, y: postOne.y },
        { x: postOne.x - 2.4, y: postOne.y },
        getCanvasCoordinate,
        canvas2DPixelScale
      )
      break
    case 'right':
      goalCanvasStyles = getCanvasRectStylesFromPitchCoordinates(
        { x: postOne.x, y: postOne.y },
        { x: postOne.x + 2.4, y: postOne.y },
        { x: postTwo.x + 2.4, y: postTwo.y },
        { x: postTwo.x, y: postTwo.y },
        getCanvasCoordinate,
        canvas2DPixelScale
      )
      break
    case 'top':
      goalCanvasStyles = getCanvasRectStylesFromPitchCoordinates(
        { x: postOne.x, y: postOne.y + 2.4 },
        { x: postTwo.x, y: postTwo.y + 2.4 },
        { x: postTwo.x, y: postTwo.y },
        { x: postOne.x, y: postOne.y },
        getCanvasCoordinate,
        canvas2DPixelScale
      )
      break
    case 'bottom':
      goalCanvasStyles = getCanvasRectStylesFromPitchCoordinates(
        { x: postTwo.x, y: postTwo.y },
        { x: postOne.x, y: postOne.y },
        { x: postOne.x, y: postOne.y - 2.4 },
        { x: postTwo.x, y: postTwo.y - 2.4 },
        getCanvasCoordinate,
        canvas2DPixelScale
      )
      break
  }

  return {
    ...goalCanvasStyles,
    side,
    id
  }
}

export const getDrillZoneStylesFromRegion = (
  region: Region,
  strack
): DrillZoneStyles => {
  const {
    getCanvasRectStylesFromPitchCoordinates,
    getCanvasCoordinate,
    canvas2DPixelScale
  } = strack

  const styles = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    goals: []
  }

  const { P1, P2, P3, P4 } = region

  const { x, y, width, height } = getCanvasRectStylesFromPitchCoordinates(
    P1,
    P2,
    P3,
    P4,
    getCanvasCoordinate,
    canvas2DPixelScale
  )

  styles.x = x
  styles.y = y

  styles.width = width
  styles.height = height

  styles.goals = []

  const goalOne = getGoalStylesFromGoalCoordinates(
    [region.P7, region.P5],
    strack,
    1
  )

  if (goalOne) {
    styles.goals.push(goalOne)
  }

  const goalTwo = getGoalStylesFromGoalCoordinates(
    [region.P6, region.P8],
    strack,
    2
  )

  if (goalTwo) {
    styles.goals.push(goalTwo)
  }

  return styles
}

export const getPostCoordinatesFromGoalStyles = (
  goalStyles: GoalStyles,
  strack
) => {
  const { getPitchCoordinatesFromCanvasRectStyles } = strack

  let postOne = {
    x: 0,
    y: 0,
    z: 0
  }

  let postTwo = {
    x: 0,
    y: 0,
    z: 0
  }

  if (!goalStyles) return [postOne, postTwo]

  const goalPitchCoordinates = getPitchCoordinatesFromCanvasRectStyles(
    goalStyles.x,
    goalStyles.y,
    goalStyles.width,
    goalStyles.height,
    2.4
  )

  switch (goalStyles.side.pitch) {
    case 'left':
      postOne = goalPitchCoordinates.bottomRightVertex
      postTwo = goalPitchCoordinates.topRightVertex
      break
    case 'right':
      postOne = goalPitchCoordinates.topLeftVertex
      postTwo = goalPitchCoordinates.bottomLeftVertex
      break
    case 'top':
      postOne = goalPitchCoordinates.bottomLeftVertex
      postTwo = goalPitchCoordinates.bottomRightVertex
      break
    case 'bottom':
      postOne = goalPitchCoordinates.topRightVertex
      postTwo = goalPitchCoordinates.topLeftVertex
      break
  }

  return [postOne, postTwo]
}

export const getRegionFromDrillZoneStyles = (
  drillZoneStyles: DrillZoneStyles,
  strack
) => {
  const { getPitchCoordinatesFromCanvasRectStyles } = strack
  const defaultPost = { x: 0, y: 0, z: 0 }

  const { topLeftVertex, topRightVertex, bottomRightVertex, bottomLeftVertex } =
    getPitchCoordinatesFromCanvasRectStyles(
      drillZoneStyles.x,
      drillZoneStyles.y,
      drillZoneStyles.width,
      drillZoneStyles.height,
      0
    )

  // Handle cases where goals array is undefined or empty
  const goalOnePosts = drillZoneStyles.goals?.[0]
    ? getPostCoordinatesFromGoalStyles(drillZoneStyles.goals[0], strack)
    : [defaultPost, defaultPost]

  const goalTwoPosts = drillZoneStyles.goals?.[1]
    ? getPostCoordinatesFromGoalStyles(drillZoneStyles.goals[1], strack)
    : [defaultPost, defaultPost]

  return {
    P1: topLeftVertex,
    P2: topRightVertex,
    P3: bottomRightVertex,
    P4: bottomLeftVertex,
    P5: {
      x: goalOnePosts[1].x,
      y: goalOnePosts[1].y,
      z: goalOnePosts[1].z
    },
    P6: {
      x: goalTwoPosts[0].x,
      y: goalTwoPosts[0].y,
      z: goalTwoPosts[0].z
    },
    P7: {
      x: goalOnePosts[0].x,
      y: goalOnePosts[0].y,
      z: goalOnePosts[0].z
    },
    P8: {
      x: goalTwoPosts[1].x,
      y: goalTwoPosts[1].y,
      z: goalTwoPosts[1].z
    }
  }
}

export function StrackDrillZone({ strack, active, strackUpdateSwitch }) {
  const { pitchPlaneCanvasCoordinates } = strack

  const dispatch = useAppDispatch()
  const { live, sport } = useSelectedFormattedSession()

  const formattedDrill = useSelectedFormattedDrill()

  const [dragging, setDragging] = useState(false)
  const [resizing, setResizing] = useState<Corners>(null)

  const [drillZoneStyles, setDrillZoneStyles] = useState<DrillZoneStyles>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    goals: []
  })

  const [savedGoals, setSavedGoals] = useState<{
    [drillId: string]: GoalStyles[]
  }>({})

  useEffect(() => {
    if (!formattedDrill) return
    setDrillZoneStyles(
      getDrillZoneStylesFromRegion(formattedDrill.region, strack)
    )
  }, [formattedDrill, strackUpdateSwitch])

  const toggleGoals = useCallback(
    (goalsEnabled) => {
      if (!formattedDrill) return

      if (goalsEnabled) {
        // Enabling goals
        if (savedGoals[formattedDrill.id]?.length > 0) {
          // Use previously saved goals if they exist
          handleRegionUpdate({
            ...drillZoneStyles,
            goals: savedGoals[formattedDrill.id]
          })
        } else {
          // Create default goals in middle of sides
          const defaultGoals: GoalStyles[] = [
            {
              id: 1,
              x:
                drillZoneStyles.x -
                sport.props.pitch.drillsGoalsOrPosts.dimensions.large.depth *
                  strack.scale,
              y:
                drillZoneStyles.y +
                drillZoneStyles.height / 2 -
                (sport.props.pitch.drillsGoalsOrPosts.dimensions.large.width *
                  strack.scale) /
                  2,
              width:
                sport.props.pitch.drillsGoalsOrPosts.dimensions.large.depth *
                strack.scale,
              height:
                sport.props.pitch.drillsGoalsOrPosts.dimensions.large.width *
                strack.scale,
              side: {
                pitch: getPitchSideFromCanvasSide('left', strack),
                canvas: 'left'
              }
            },
            {
              id: 2,
              x: drillZoneStyles.x + drillZoneStyles.width,
              y:
                drillZoneStyles.y +
                drillZoneStyles.height / 2 -
                (sport.props.pitch.drillsGoalsOrPosts.dimensions.large.width *
                  strack.scale) /
                  2,
              width:
                sport.props.pitch.drillsGoalsOrPosts.dimensions.large.depth *
                strack.scale,
              height:
                sport.props.pitch.drillsGoalsOrPosts.dimensions.large.width *
                strack.scale,
              side: {
                pitch: getPitchSideFromCanvasSide('right', strack),
                canvas: 'right'
              }
            }
          ]

          // Save the default goals to savedGoals state as well
          setSavedGoals({
            ...savedGoals,
            [formattedDrill.id]: defaultGoals
          })

          handleRegionUpdate({
            ...drillZoneStyles,
            goals: defaultGoals
          })
        }
      } else {
        // Disabling goals - save current positions before removing
        setSavedGoals({
          ...savedGoals,
          [formattedDrill.id]: drillZoneStyles.goals
        })
        handleRegionUpdate({
          ...drillZoneStyles,
          goals: []
        })
      }
    },
    [
      drillZoneStyles,
      savedGoals,
      formattedDrill,
      strack,
      sport.props.pitch.drillsGoalsOrPosts.dimensions
    ]
  )

  const switchGoalOrientation = useCallback(() => {
    const newGoals = drillZoneStyles.goals.map((goal) => {
      if (goal.side.canvas === 'left') {
        return {
          ...goal,
          width: goal.height,
          height: goal.width,
          x: drillZoneStyles.x + drillZoneStyles.width / 2 - goal.height / 2,
          y: drillZoneStyles.y - goal.width,
          side: {
            pitch: getPitchSideFromCanvasSide('top', strack),
            canvas: 'top' as const
          }
        }
      } else if (goal.side.canvas === 'right') {
        return {
          ...goal,
          width: goal.height,
          height: goal.width,
          x: drillZoneStyles.x + drillZoneStyles.width / 2 - goal.height / 2,
          y: drillZoneStyles.y + drillZoneStyles.height,
          side: {
            pitch: getPitchSideFromCanvasSide('bottom', strack),
            canvas: 'bottom' as const
          }
        }
      } else if (goal.side.canvas === 'top') {
        return {
          ...goal,
          width: goal.height,
          height: goal.width,
          x: drillZoneStyles.x + drillZoneStyles.width,
          y: drillZoneStyles.y + drillZoneStyles.height / 2 - goal.width / 2,
          side: {
            pitch: getPitchSideFromCanvasSide('right', strack),
            canvas: 'right' as const
          }
        }
      } else if (goal.side.canvas === 'bottom') {
        return {
          ...goal,
          width: goal.height,
          height: goal.width,
          x: drillZoneStyles.x - goal.height,
          y: drillZoneStyles.y + drillZoneStyles.height / 2 - goal.width / 2,
          side: {
            pitch: getPitchSideFromCanvasSide('left', strack),
            canvas: 'left' as const
          }
        }
      }
      return goal
    })

    handleRegionUpdate({
      ...drillZoneStyles,
      goals: newGoals
    })
  }, [drillZoneStyles])

  const switchGoalSize = useCallback(
    (size) => {
      const newGoals = drillZoneStyles.goals.map((goal) => {
        const goalDimensions =
          sport.props.pitch.drillsGoalsOrPosts.dimensions[size]
        if (goal.side.canvas === 'left' || goal.side.canvas === 'right') {
          return {
            ...goal,
            height: goalDimensions.width * strack.scale,
            y: goal.y + (goal.height - goalDimensions.width * strack.scale) / 2
          }
        }
        return {
          ...goal,
          width: goalDimensions.width * strack.scale,
          x: goal.x + (goal.width - goalDimensions.width * strack.scale) / 2
        }
      })
      handleRegionUpdate({
        ...drillZoneStyles,
        goals: newGoals
      })
    },
    [drillZoneStyles]
  )

  const startPost = useRef(null)

  const onMouseDownDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    startPost.current = {
      x: e.clientX,
      y: e.clientY
    }
    setDragging(true)
  }

  const onMouseDownResize = useCallback((e, corner: Corners) => {
    e.preventDefault()
    e.stopPropagation()
    startPost.current = {
      x: e.clientX,
      y: e.clientY
    }
    setResizing(corner)
  }, [])

  const prevX = useRef(null)
  const prevY = useRef(null)
  const prevDx = useRef(null)
  const prevDy = useRef(null)
  const prevWidth = useRef(null)
  const prevHeight = useRef(null)

  const onMouseMove = useCallback(
    (e) => {
      if (!startPost.current) return

      function isDrillZoneOutOfBoundsState(x, y, width, height) {
        return {
          left: x < 0,
          right: x + width > strack.canvasSectionWidth,
          top: y < 0,
          bottom: y + height > strack.canvasHeight
        }
      }

      let dx = e.clientX - startPost.current.x
      let dy = e.clientY - startPost.current.y

      let x = drillZoneStyles.x
      let y = drillZoneStyles.y

      let width = drillZoneStyles.width
      let height = drillZoneStyles.height

      switch (resizing) {
        case 'topLeft':
          height = Math.max(20, drillZoneStyles.height - dy)
          width = Math.max(20, drillZoneStyles.width - dx)
          x = drillZoneStyles.x + dx
          y = drillZoneStyles.y + dy
          break
        case 'topRight':
          height = Math.max(20, drillZoneStyles.height - dy)
          width = Math.max(20, drillZoneStyles.width + dx)
          y = drillZoneStyles.y + dy
          break
        case 'bottomLeft':
          height = Math.max(20, drillZoneStyles.height + dy)
          width = Math.max(20, drillZoneStyles.width - dx)
          x = drillZoneStyles.x + dx
          break
        case 'bottomRight':
          height = Math.max(20, drillZoneStyles.height + dy)
          width = Math.max(20, drillZoneStyles.width + dx)
          break
        default:
          x = drillZoneStyles.x + dx
          y = drillZoneStyles.y + dy
          break
      }

      const isDrillZoneOutOfBounds = isDrillZoneOutOfBoundsState(
        x,
        y,
        width,
        height
      )

      if (isDrillZoneOutOfBounds.left) {
        dx = prevDx.current
        x = prevX.current
        width = prevWidth.current
      }

      if (isDrillZoneOutOfBounds.right) {
        dx = prevDx.current
        x = prevX.current
        width = prevWidth.current
      }

      if (isDrillZoneOutOfBounds.top) {
        dy = prevDy.current
        y = prevY.current
        height = prevHeight.current
      }

      if (isDrillZoneOutOfBounds.bottom) {
        dy = prevDy.current
        y = prevY.current
        height = prevHeight.current
      }

      prevDx.current = dx
      prevDy.current = dy
      prevX.current = x
      prevY.current = y
      prevWidth.current = width
      prevHeight.current = height

      if (dragging) {
        // Update goal positions //
        const newGoals = drillZoneStyles.goals.map((goal) => {
          return {
            ...goal,
            x: goal.x + dx,
            y: goal.y + dy
          }
        })

        const newSaveGoals =
          savedGoals[formattedDrill.id]?.map((goal) => {
            return {
              ...goal,
              x: goal.x + dx,
              y: goal.y + dy
            }
          }) || []

        const newStyles = {
          ...drillZoneStyles,
          x: dx + drillZoneStyles.x,
          y: dy + drillZoneStyles.y,
          goals: newGoals
        }

        setDrillZoneStyles(newStyles)

        setSavedGoals({
          ...savedGoals,
          [formattedDrill.id]: newSaveGoals
        })
      }
      if (resizing) {
        // Update goal positions //
        let newGoals, newSavedGoals

        switch (resizing) {
          case 'topLeft':
            newGoals = handleGoalPositionOnDrillZoneResize(
              drillZoneStyles.goals,
              x,
              y,
              dx,
              dy,
              width,
              height,
              'topLeft'
            )

            newSavedGoals = handleGoalPositionOnDrillZoneResize(
              savedGoals[formattedDrill.id],
              x,
              y,
              dx,
              dy,
              width,
              height,
              'topLeft'
            )

            break
          case 'topRight':
            newGoals = handleGoalPositionOnDrillZoneResize(
              drillZoneStyles.goals,
              x,
              y,
              dx,
              dy,
              width,
              height,
              'topRight'
            )

            newSavedGoals = handleGoalPositionOnDrillZoneResize(
              savedGoals[formattedDrill.id],
              x,
              y,
              dx,
              dy,
              width,
              height,
              'topRight'
            )

            break
          case 'bottomLeft':
            newGoals = handleGoalPositionOnDrillZoneResize(
              drillZoneStyles.goals,
              x,
              y,
              dx,
              dy,
              width,
              height,
              'bottomLeft'
            )

            newSavedGoals = handleGoalPositionOnDrillZoneResize(
              savedGoals[formattedDrill.id],
              x,
              y,
              dx,
              dy,
              width,
              height,
              'bottomLeft'
            )

            break
          case 'bottomRight':
            newGoals = handleGoalPositionOnDrillZoneResize(
              drillZoneStyles.goals,
              x,
              y,
              dx,
              dy,
              width,
              height,
              'bottomRight'
            )

            newSavedGoals = handleGoalPositionOnDrillZoneResize(
              savedGoals[formattedDrill.id],
              x,
              y,
              dx,
              dy,
              width,
              height,
              'bottomRight'
            )

            break
        }

        setDrillZoneStyles({
          ...drillZoneStyles,
          x,
          y,
          width,
          height,
          goals: newGoals
        })

        setSavedGoals({
          ...savedGoals,
          [formattedDrill.id]: newSavedGoals
        })
      }
    },
    [dragging, resizing]
  )

  const onMouseUp = useCallback(() => {
    if (dragging || resizing) {
      handleRegionUpdate(drillZoneStyles)
    }
    setDragging(false)
    setResizing(null)
  }, [drillZoneStyles])

  useEffect(() => {
    document.removeEventListener('mousemove', onMouseMove)
    document.addEventListener('mousemove', onMouseMove)

    document.removeEventListener('mouseup', onMouseUp)
    document.addEventListener('mouseup', onMouseUp)
    return () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }
  }, [onMouseMove, onMouseUp])

  const handleRegionUpdate = useCallback(
    (drillZoneStyles: DrillZoneStyles) => {
      // Return early if no drill or if drill has already started
      if (!formattedDrill || formattedDrill.isStarted) return
      const updatedRegion = getRegionFromDrillZoneStyles(
        drillZoneStyles,
        strack
      )

      dispatch(
        updateExistingDrill({
          ...formattedDrill.rawData,
          extraInfo: {
            ...formattedDrill.rawData.extraInfo,
            region: {
              ...formattedDrill.rawData.extraInfo.region,
              ...updatedRegion
            }
          }
        })
      )
    },
    [formattedDrill]
  )

  const debouncedRegionUpdate = useMemo(
    () =>
      _.debounce((styles) => {
        if (!formattedDrill || formattedDrill.isStarted) return

        const updatedRegion = getRegionFromDrillZoneStyles(styles, strack)
        dispatch(
          updateExistingDrill({
            ...formattedDrill.rawData,
            extraInfo: {
              ...formattedDrill.rawData.extraInfo,
              region: {
                ...formattedDrill.rawData.extraInfo.region,
                ...updatedRegion
              }
            }
          })
        )
      }, 500),
    [formattedDrill, strack]
  )

  const handleArrowKeyPress = useCallback(
    (e) => {
      // check if it's an arrow key before preventing default
      if (
        !['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)
      ) {
        return
      }

      e.preventDefault()

      if (!active || !live || !formattedDrill || formattedDrill.isStarted)
        return

      const moveStep = 5
      const newStyles = { ...drillZoneStyles }

      switch (e.key) {
        case 'ArrowUp':
          newStyles.goals = drillZoneStyles.goals.map((goal) => ({
            ...goal,
            y: goal.y - moveStep
          }))
          newStyles.y = drillZoneStyles.y - moveStep
          break
        case 'ArrowDown':
          newStyles.goals = drillZoneStyles.goals.map((goal) => ({
            ...goal,
            y: goal.y + moveStep
          }))
          newStyles.y = drillZoneStyles.y + moveStep
          break
        case 'ArrowLeft':
          newStyles.goals = drillZoneStyles.goals.map((goal) => ({
            ...goal,
            x: goal.x - moveStep
          }))
          newStyles.x = drillZoneStyles.x - moveStep
          break
        case 'ArrowRight':
          newStyles.goals = drillZoneStyles.goals.map((goal) => ({
            ...goal,
            x: goal.x + moveStep
          }))
          newStyles.x = drillZoneStyles.x + moveStep
          break
        default:
          return
      }

      setDrillZoneStyles(newStyles)

      setSavedGoals({
        ...savedGoals,
        [formattedDrill.id]: newStyles.goals
      })

      // Trigger debounced update
      debouncedRegionUpdate(newStyles)
    },
    [
      active,
      live,
      formattedDrill,
      drillZoneStyles,
      savedGoals,
      debouncedRegionUpdate
    ]
  )

  useEffect(() => {
    return () => {
      debouncedRegionUpdate.cancel()
    }
  }, [debouncedRegionUpdate])

  useEffect(() => {
    document.addEventListener('keydown', handleArrowKeyPress)
    return () => document.removeEventListener('keydown', handleArrowKeyPress)
  }, [handleArrowKeyPress])

  const handleDirectionChange = (index, bibId) => {
    if (!live || formattedDrill.isStarted) return

    // Set new direction
    const newBibTeamDirection = [
      ...formattedDrill.rawData.extraInfo.bibTeamDirection
    ]
    newBibTeamDirection[index] = parseInt(bibId)

    // If the other direction is the same bib change it
    const otherIndex = index === 0 ? 1 : 0
    if (newBibTeamDirection[otherIndex] === parseInt(bibId)) {
      newBibTeamDirection[otherIndex] =
        formattedDrill.rawData.extraInfo.bibTeamDirection[index]
    }

    // Update the drill with the new directions
    dispatch(
      updateExistingDrill({
        ...formattedDrill.rawData,
        extraInfo: {
          ...formattedDrill.rawData.extraInfo,
          bibTeamDirection: newBibTeamDirection
        }
      })
    )
  }

  const handleSnapToLine = (snapLine: string) => {
    if (!live || formattedDrill.isStarted) return

    // Get the canvas coordinate for the snap line
    const coord = strack.getCanvasCoordinate(
      strack.pixelScale,
      strack.dimensions[snapLine].x,
      strack.dimensions[snapLine].y
    )

    // When pitch is vertical, use scaleY for snapping instead of scaleX
    const snapCoordinate = strack.pitchRotated ? coord.scaleY : coord.scaleX

    // Calculate distances based on pitch orientation
    const leftEdgeDistance = Math.abs(
      snapCoordinate -
        (strack.pitchRotated ? drillZoneStyles.y : drillZoneStyles.x)
    )
    const rightEdgeDistance = Math.abs(
      snapCoordinate -
        (strack.pitchRotated
          ? drillZoneStyles.y + drillZoneStyles.height
          : drillZoneStyles.x + drillZoneStyles.width)
    )

    let newStyles, newSavedGoals

    // Handle vertical pitch orientation
    if (strack.pitchRotated) {
      if (leftEdgeDistance <= rightEdgeDistance) {
        const dy = snapCoordinate - drillZoneStyles.y

        const newGoals = drillZoneStyles.goals.map((goal) => {
          const centerOfDrillZone =
            drillZoneStyles.x + drillZoneStyles.width / 2

          if (goal.side.canvas === 'top') {
            return {
              ...goal,
              y: snapCoordinate - goal.height,
              x: centerOfDrillZone - goal.width / 2
            }
          }
          if (goal.side.canvas === 'bottom') {
            return {
              ...goal,
              y: snapCoordinate + (drillZoneStyles.height - dy),
              x: centerOfDrillZone - goal.width / 2
            }
          }
          if (goal.side.canvas === 'left') {
            return {
              ...goal,
              y:
                snapCoordinate +
                (drillZoneStyles.height - dy) / 2 -
                goal.height / 2
            }
          }
          if (goal.side.canvas === 'right') {
            return {
              ...goal,
              y:
                snapCoordinate +
                (drillZoneStyles.height - dy) / 2 -
                goal.height / 2
            }
          }
          return goal
        })

        if (savedGoals[formattedDrill.id]) {
          newSavedGoals = savedGoals[formattedDrill.id].map((goal) => {
            const centerOfDrillZone =
              drillZoneStyles.x + drillZoneStyles.width / 2

            if (goal.side.canvas === 'top') {
              return {
                ...goal,
                y: snapCoordinate - goal.height,
                x: centerOfDrillZone - goal.width / 2
              }
            }
            if (goal.side.canvas === 'bottom') {
              return {
                ...goal,
                y: snapCoordinate + (drillZoneStyles.height - dy),
                x: centerOfDrillZone - goal.width / 2
              }
            }
            if (goal.side.canvas === 'left') {
              return {
                ...goal,
                y:
                  snapCoordinate +
                  (drillZoneStyles.height - dy) / 2 -
                  goal.height / 2
              }
            }
            if (goal.side.canvas === 'right') {
              return {
                ...goal,
                y:
                  snapCoordinate +
                  (drillZoneStyles.height - dy) / 2 -
                  goal.height / 2
              }
            }
            return goal
          })
        }

        newStyles = {
          ...drillZoneStyles,
          y: snapCoordinate,
          height: drillZoneStyles.height - dy,
          goals: newGoals
        }
      } else {
        const centerOfDrillZone = drillZoneStyles.x + drillZoneStyles.width / 2

        const newGoals = drillZoneStyles.goals.map((goal) => {
          if (goal.side.canvas === 'bottom') {
            return {
              ...goal,
              y: snapCoordinate,
              x: centerOfDrillZone - goal.width / 2
            }
          }
          if (goal.side.canvas === 'left' || goal.side.canvas === 'right') {
            return {
              ...goal,
              y:
                drillZoneStyles.y +
                (snapCoordinate - drillZoneStyles.y) / 2 -
                goal.height / 2
            }
          }
          return goal
        })

        if (savedGoals[formattedDrill.id]) {
          newSavedGoals = savedGoals[formattedDrill.id].map((goal) => {
            if (goal.side.canvas === 'bottom') {
              return {
                ...goal,
                y: snapCoordinate,
                x: centerOfDrillZone - goal.width / 2
              }
            }
            if (goal.side.canvas === 'left' || goal.side.canvas === 'right') {
              return {
                ...goal,
                y:
                  drillZoneStyles.y +
                  (snapCoordinate - drillZoneStyles.y) / 2 -
                  goal.height / 2
              }
            }
            return goal
          })
        }

        newStyles = {
          ...drillZoneStyles,
          height: snapCoordinate - drillZoneStyles.y,
          goals: newGoals
        }
      }
    }
    // Handle horizontal pitch orientation
    else {
      if (leftEdgeDistance <= rightEdgeDistance) {
        const dx = snapCoordinate - drillZoneStyles.x

        const newGoals = drillZoneStyles.goals.map((goal) => {
          const centerOfDrillZone =
            drillZoneStyles.y + drillZoneStyles.height / 2

          if (goal.side.canvas === 'left') {
            return {
              ...goal,
              x: snapCoordinate - goal.width,
              y: centerOfDrillZone - goal.height / 2
            }
          }
          if (goal.side.canvas === 'right') {
            return {
              ...goal,
              x: snapCoordinate + (drillZoneStyles.width - dx),
              y: centerOfDrillZone - goal.height / 2
            }
          }
          if (goal.side.canvas === 'top' || goal.side.canvas === 'bottom') {
            return {
              ...goal,
              x:
                snapCoordinate +
                (drillZoneStyles.width - dx) / 2 -
                goal.width / 2
            }
          }
          return goal
        })

        if (savedGoals[formattedDrill.id]) {
          newSavedGoals = savedGoals[formattedDrill.id].map((goal) => {
            const centerOfDrillZone =
              drillZoneStyles.y + drillZoneStyles.height / 2

            if (goal.side.canvas === 'left') {
              return {
                ...goal,
                x: snapCoordinate - goal.width,
                y: centerOfDrillZone - goal.height / 2
              }
            }
            if (goal.side.canvas === 'right') {
              return {
                ...goal,
                x: snapCoordinate + (drillZoneStyles.width - dx),
                y: centerOfDrillZone - goal.height / 2
              }
            }
            if (goal.side.canvas === 'top' || goal.side.canvas === 'bottom') {
              return {
                ...goal,
                x:
                  snapCoordinate +
                  (drillZoneStyles.width - dx) / 2 -
                  goal.width / 2
              }
            }
            return goal
          })
        }

        newStyles = {
          ...drillZoneStyles,
          x: snapCoordinate,
          width: drillZoneStyles.width - dx,
          goals: newGoals
        }
      } else {
        const centerOfDrillZone = drillZoneStyles.y + drillZoneStyles.height / 2

        const newGoals = drillZoneStyles.goals.map((goal) => {
          if (goal.side.canvas === 'right') {
            return {
              ...goal,
              x: snapCoordinate,
              y: centerOfDrillZone - goal.height / 2
            }
          }
          if (goal.side.canvas === 'top' || goal.side.canvas === 'bottom') {
            return {
              ...goal,
              x:
                drillZoneStyles.x +
                (snapCoordinate - drillZoneStyles.x) / 2 -
                goal.width / 2
            }
          }
          return goal
        })

        if (savedGoals[formattedDrill.id]) {
          newSavedGoals = savedGoals[formattedDrill.id].map((goal) => {
            if (goal.side.canvas === 'right') {
              return {
                ...goal,
                x: snapCoordinate,
                y: centerOfDrillZone - goal.height / 2
              }
            }
            if (goal.side.canvas === 'top' || goal.side.canvas === 'bottom') {
              return {
                ...goal,
                x:
                  drillZoneStyles.x +
                  (snapCoordinate - drillZoneStyles.x) / 2 -
                  goal.width / 2
              }
            }
            return goal
          })
        }

        newStyles = {
          ...drillZoneStyles,
          width: snapCoordinate - drillZoneStyles.x,
          goals: newGoals
        }
      }
    }

    setDrillZoneStyles(newStyles)

    setSavedGoals({
      ...savedGoals,
      [formattedDrill.id]: newSavedGoals
    })

    handleRegionUpdate(newStyles)
  }

  if (!formattedDrill) {
    return null
  }

  const handleGoalStylesUpdate = (goalStyles: GoalStyles) => {
    setDrillZoneStyles((prevStyles) => {
      const newGoals = [...prevStyles.goals]
      const goalIndex = newGoals.findIndex((gs) => gs.id === goalStyles.id)
      newGoals[goalIndex] = goalStyles
      return {
        ...prevStyles,
        goals: newGoals
      }
    })
  }

  const canvasPitchTop = getPitchSideFromCanvasSide('top', strack)
  const canvasPitchBottom = getPitchSideFromCanvasSide('bottom', strack)
  const canvasPitchLeft = getPitchSideFromCanvasSide('left', strack)

  const isVerticalOrientation =
    drillZoneStyles.goals.length > 0 &&
    (drillZoneStyles.goals[0].side.canvas === 'top' ||
      drillZoneStyles.goals[0].side.canvas === 'bottom')

  return (
    <div className={styles.drillZoneContainer}>
      {/* GOALS */}
      {drillZoneStyles.goals.map((goalStyles) => (
        <DraggableGoal
          key={goalStyles.id}
          drillZoneStyles={drillZoneStyles}
          goalStyles={goalStyles}
          handleGoalStylesUpdate={handleGoalStylesUpdate}
          handleFullUpdate={() => {
            handleRegionUpdate(drillZoneStyles)
          }}
          disabled={!live || formattedDrill.isStarted}
        />
      ))}

      <div
        style={{
          width: `${drillZoneStyles.width}px`,
          height: `${drillZoneStyles.height}px`,
          top: `${drillZoneStyles.y}px`,
          left: `${drillZoneStyles.x}px`,
          position: 'relative',
          cursor: !live || formattedDrill.isStarted ? 'not-allowed' : 'grab'
        }}
        className={styles.drillZone}
        onMouseDown={
          !live || formattedDrill.isStarted ? undefined : onMouseDownDrag
        }
      >
        <div
          className={`${styles.resizeHandle} ${styles.resizeHandleTopLeft}`}
          onMouseDown={
            !live || formattedDrill.isStarted
              ? undefined
              : (e) => onMouseDownResize(e, 'topLeft')
          }
        ></div>
        <div
          className={`${styles.resizeHandle} ${styles.resizeHandleTopRight}`}
          onMouseDown={
            !live || formattedDrill.isStarted
              ? undefined
              : (e) => onMouseDownResize(e, 'topRight')
          }
        ></div>
        <div
          className={`${styles.resizeHandle} ${styles.resizeHandleBottomRight}`}
          onMouseDown={
            !live || formattedDrill.isStarted
              ? undefined
              : (e) => onMouseDownResize(e, 'bottomRight')
          }
        ></div>
        <div
          className={`${styles.resizeHandle} ${styles.resizeHandleBottomLeft}`}
          onMouseDown={
            !live || formattedDrill.isStarted
              ? undefined
              : (e) => onMouseDownResize(e, 'bottomLeft')
          }
        ></div>
      </div>
      {/* Goals or Posts Control Panel */}
      {sport.props.pitch?.drillsGoalsOrPosts?.enabled && (
        <div
          style={{
            width: `600px`,
            height: '30px',
            padding: '0 10px',
            boxSizing: 'border-box',
            top: `${pitchPlaneCanvasCoordinates[canvasPitchTop] - 55}px`,
            left: `50%`,
            transform: 'translateX(-50%)',
            position: 'absolute',
            zIndex: 100000,
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pointerEvents: 'auto'
          }}
        >
          <Checkbox
            onClicked={(value) => toggleGoals(value)}
            label={'Enable goals'}
            checked={drillZoneStyles.goals.length > 0}
            textColour='white'
            disabled={!live || formattedDrill.isStarted}
          />
          {live && formattedDrill.isPending && (
            <>
              {' '}
              {drillZoneStyles.goals.length > 0 && (
                <button onClick={switchGoalOrientation}>
                  Switch Goal Orientation
                </button>
              )}
              {drillZoneStyles.goals.length > 0 && (
                <button onClick={() => switchGoalSize('large')}>Goals +</button>
              )}
              {drillZoneStyles.goals.length > 0 && (
                <button onClick={() => switchGoalSize('small')}>Goals -</button>
              )}
            </>
          )}
        </div>
      )}
      {/* Snap Lines Controls */}
      {sport?.props?.pitch?.drillSnapLines && (
        <div
          style={{
            width: '100%',
            height: '100%'
          }}
        >
          {sport.props.pitch.drillSnapLines.map((snapLine) => {
            const coord = strack.getCanvasCoordinate(
              strack.pixelScale,
              strack.dimensions[snapLine].x,
              strack.dimensions[snapLine].y
            )

            return (
              <button
                key={snapLine}
                onClick={() => handleSnapToLine(snapLine)}
                disabled={!live || formattedDrill.isStarted}
                style={{
                  position: 'absolute',
                  ...(strack.pitchRotated
                    ? {
                        top: coord.scaleY,
                        left: pitchPlaneCanvasCoordinates[canvasPitchLeft] - 5,
                        transform: 'translateY(-50%) translateX(-100%)'
                      }
                    : {
                        left: coord.scaleX,
                        top: pitchPlaneCanvasCoordinates[canvasPitchTop] - 3,
                        transform: 'translateX(-50%) translateY(-100%)'
                      })
                }}
              >
                Snap
              </button>
            )
          })}
        </div>
      )}
      {/* BIB Direction Controls */}
      {formattedDrill.bibDirection && formattedDrill.goalsOrPostsEnabled && (
        <div
          style={{
            width: `600px`,
            height: '30px',
            padding: '0 10px',
            boxSizing: 'border-box',
            top: `${pitchPlaneCanvasCoordinates[canvasPitchBottom] + 20}px`,
            left: `50%`,
            transform: 'translateX(-50%)',
            position: 'absolute',
            zIndex: 100000,
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '2px'
              }}
            >
              <select
                value={formattedDrill.bibDirection[0].id}
                onChange={(e) => handleDirectionChange(0, e.target.value)}
                disabled={!live || formattedDrill.isStarted}
                className={styles.bibSelect}
              >
                {formattedDrill.bibs.options
                  .filter((b) => b.value !== 0)
                  .map((bib) => (
                    <option key={bib.value} value={bib.value}>
                      {formattedDrill.bibs.map[bib.value].name}
                    </option>
                  ))}
              </select>
              {isVerticalOrientation ? (
                <ArrowCircleDownIcon
                  sx={{
                    color: formattedDrill.bibDirection[0].colour,
                    fontSize: '30px',
                    transform: 'rotate(0deg)'
                  }}
                />
              ) : (
                <ArrowCircleRightIcon
                  sx={{
                    color: formattedDrill.bibDirection[0].colour,
                    fontSize: '30px'
                  }}
                />
              )}
            </div>
          </div>

          {/* Switch side button - hidden when drill has started */}
          {formattedDrill.isPending && (
            <div
              style={{
                width: `30px`,
                height: '30px',
                zIndex: 100000,
                pointerEvents: 'auto',
                cursor: 'pointer'
              }}
              onClick={() => {
                // Switch the bib team direction //
                const newBibTeamDirection =
                  formattedDrill.rawData.extraInfo.bibTeamDirection
                    .slice()
                    .reverse()

                dispatch(
                  updateExistingDrill({
                    ...formattedDrill.rawData,
                    extraInfo: {
                      ...formattedDrill.rawData.extraInfo,
                      bibTeamDirection: newBibTeamDirection
                    }
                  })
                )
              }}
            >
              <SwapHorizontalCircleIcon
                sx={{
                  color: 'white',
                  fontSize: '30px',
                  transform: isVerticalOrientation
                    ? 'rotate(90deg)'
                    : 'rotate(0deg)'
                }}
              />
            </div>
          )}

          <div
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '2px'
              }}
            >
              {isVerticalOrientation ? (
                <ArrowCircleUpIcon
                  sx={{
                    color: formattedDrill.bibDirection[1].colour,
                    fontSize: '30px',
                    transform: 'rotate(0deg)'
                  }}
                />
              ) : (
                <ArrowCircleLeftIcon
                  sx={{
                    color: formattedDrill.bibDirection[1].colour,
                    fontSize: '30px'
                  }}
                />
              )}
              <select
                value={formattedDrill.bibDirection[1].id}
                onChange={(e) => handleDirectionChange(1, e.target.value)}
                disabled={!live || formattedDrill.isStarted}
                className={styles.bibSelect}
              >
                {formattedDrill.bibs.options
                  .filter((b) => b.value !== 0)
                  .map((bib) => (
                    <option key={bib.value} value={bib.value}>
                      {formattedDrill.bibs.map[bib.value].name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
